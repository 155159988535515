<template>
	<div>
		<page-title title="Dashboard" class="mb-6"></page-title>

		<info-boxes :key="key"></info-boxes>

		<Banners />

		<v-row>
			<v-col cols="12" lg="7" xl="8" class="pr-lg-1">
				<activity-feed :key="key"></activity-feed>
			</v-col>
			<v-col cols="12" lg="5" xl="4">
				<leaderboard :key="key"></leaderboard>
			</v-col>
		</v-row>

		<v-row class="mb-4">
			<v-col cols="12" md="6" class="pr-lg-1">
				<sales-breakdown :key="key"></sales-breakdown>
			</v-col>
			<v-col cols="12" md="6">
				<join-discord></join-discord>
			</v-col>
		</v-row>

		<!-- Global TTS Enable Popup -->
		<GlobalTTSPopup :modal="globalTtsPopup" @skip="actions.skipGlobalPopup" @close="globalTtsPopup = false" />
		<ContinueGlobalTTSPopup :modal="continuePopup" @close="actions.closeGlobalPopups" />
	</div>
</template>
<script>
import { onBeforeUnmount, ref } from '@vue/composition-api';
import { MessageEnum } from '../utils/constants';
import PageTitle from '@/components/PageTitle.vue';
import InfoBoxes from './streamer/home/InfoBoxes.vue';
import Banners from './streamer/home/promotion/Banners.vue';
import ActivityFeed from './streamer/home/ActivityFeed.vue';
import Leaderboard from './streamer/home/LeaderBoard/Leaderboard.vue';
import SalesBreakdown from './streamer/home/SalesBreakdown.vue';
import JoinDiscord from './streamer/home/JoinDiscord.vue';
import GlobalTTSPopup from '@/components/GlobalTTSPopup.vue';
import ContinueGlobalTTSPopup from '@/components/ContinueGlobalTTSPopup.vue';

export default {
	components: {
		PageTitle,
		InfoBoxes,
		Banners,
		ActivityFeed,
		Leaderboard,
		SalesBreakdown,
		JoinDiscord,
		GlobalTTSPopup,
		ContinueGlobalTTSPopup,
	},
	setup() {
		const globalTtsPopup = ref(false);
		const continuePopup = ref(false);

		// Set a key so we can refresh child components per web socket messages.
		const key = ref(new Date().getTime());

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.EXTENSION_STATUS) {
				// Wait 5 seconds to let the server polling reload streamer status.
				setTimeout(() => {
					key.value = new Date().getTime();
				}, 5000);
			} else if (
				payload.method == MessageEnum.BITS_TRANSACTION ||
				payload.method == MessageEnum.TTS_APPROVAL_EXPIRED ||
				payload.method == MessageEnum.TTS_PENDING_APPROVAL ||
				payload.method == MessageEnum.USER_CONFIG_CHANGE
			) {
				key.value = new Date().getTime();
			}
		});

		onBeforeUnmount(() => {
			eventBus.detachAll();
		});

		const actions = {
			skipGlobalPopup: function () {
				globalTtsPopup.value = false;
				continuePopup.value = true;
			},

			closeGlobalPopups: function () {
				continuePopup.value = false;
			},
		};

		return { globalTtsPopup, continuePopup, actions, key };
	},
};
</script>
