var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.tts && _vm.item.tts.bounds)?_c('div',{staticClass:"base-image",style:({
			backgroundImage: ("url(" + (_vm.actions.getFullStickerImageUrl(_vm.item))),
			minHeight: _vm.actions.calcHeight(),
			minWidth: _vm.actions.calcWidth(),
			maxHeight: _vm.actions.calcHeight(),
			maxWidth: _vm.actions.calcWidth(),
			backgroundPosition: 'center center',
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
		})},[(_vm.item.tts.isEyeEnabled && _vm.item.tts.eyeImage && _vm.item.tts.bounds)?_c('div',{style:({
				position: 'absolute',
				width: '100%',
				height: '100%',
			})},[_c('div',{style:({
					position: 'absolute',
					backgroundImage: ("url(" + (_vm.actions.getImage('eyes', _vm.item.tts.eyeImage)) + ")"),
					backgroundSize: 'contain',
					backgroundPosition: 'center center',
					zIndex: 4,
					transform: _vm.item.tts.isEyeFlipped
						? ("rotate(" + (_vm.item.tts.position.eyeAngle) + "deg) scaleX(-1)")
						: ("rotate(" + (_vm.item.tts.position.eyeAngle) + "deg) scaleX(1)"),
					left: (((_vm.item.tts.bounds.eyeX / 250) * 100) + "%"),
					top: (((_vm.item.tts.bounds.eyeY / 300) * 100) + "%"),
					width: (((_vm.item.tts.bounds.eyeW / 100) * _vm.size) + "px"),
					height: (((_vm.item.tts.bounds.eyeH / 100) * _vm.size) + "px"),
				})})]):_vm._e(),(_vm.item.tts.isMouthEnabled && _vm.item.tts.mouthImage && _vm.item.tts.bounds && _vm.isAnimatedMouthRequired)?_c('div',{style:({
				position: 'absolute',
				width: '100%',
				height: '100%',
			})},[_c('div',{style:({
					position: 'absolute',
					backgroundImage: ("url(" + (_vm.actions.getImage('mouth', _vm.item.tts.mouthImage)) + ")"),
					backgroundSize: 'contain',
					backgroundPosition: 'center center',
					zIndex: 4,
					transform: _vm.item.tts.isMouthFlipped
						? ("rotate(" + (_vm.item.tts.position.mouthAngle) + "deg) scaleX(-1)")
						: ("rotate(" + (_vm.item.tts.position.mouthAngle) + "deg) scaleX(1)"),
					left: (((_vm.item.tts.bounds.mouthX / 250) * 100) + "%"),
					top: (((_vm.item.tts.bounds.mouthY / 300) * 100) + "%"),
					width: (((_vm.item.tts.bounds.mouthW / 100) * _vm.size) + "px"),
					height: (((_vm.item.tts.bounds.mouthH / 100) * _vm.size) + "px"),
				})})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.tts.isMouthEnabled && _vm.item.tts.isClosedMouthEnabled && !_vm.isAnimatedMouthRequired),expression:"item.tts.isMouthEnabled && item.tts.isClosedMouthEnabled && !isAnimatedMouthRequired"}],style:({
				position: 'absolute',
				width: '100%',
				height: '100%',
			})},[_c('div',{style:({
					position: 'absolute',
					backgroundImage: ("url(" + (_vm.actions.getClosedMouth(_vm.item)) + ")"),
					backgroundSize: 'contain',
					backgroundPosition: 'center center',
					zIndex: 4,
					transform: _vm.item.tts.isMouthFlipped
						? ("rotate(" + (_vm.item.tts.position.mouthAngle) + "deg) scaleX(-1)")
						: ("rotate(" + (_vm.item.tts.position.mouthAngle) + "deg) scaleX(1)"),
					left: (((_vm.item.tts.bounds.mouthX / 250) * 100) + "%"),
					top: (((_vm.item.tts.bounds.mouthY / 300) * 100) + "%"),
					width: (((_vm.item.tts.bounds.mouthW / 100) * _vm.size) + "px"),
					height: (((_vm.item.tts.bounds.mouthH / 100) * _vm.size) + "px"),
				})})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }