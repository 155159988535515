<template>
	<div>
		<v-card class="mb-6 join-discord" height="290">
			<v-card-text class="pt-3">
				<v-row>
					<v-col cols="12" md="6" lg="7">
						<h2 class="fs--23 fw--500">Join our Discord</h2>
						<div class="text-center text-md-left text-lg-center pr-lg-5 mt-4">
							<img src="../../../assets/images/avatars/discord.png" height="100" width="120" />
						</div>
						<h3 class="d-block d-md-none d-lg-block fs--16 fw--400">
							Join our Discord to download Bonus Stickers!
						</h3>
						<h3 class="d-none d-md-block d-lg-none fs--14 fw--400">
							Join our Discord to download Bonus Stickers!
						</h3>
						<v-btn @click="openDiscord()" color="pink" depressed class="mt-4" block>Join Now</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-img class="character-image d-none d-md-block" src="../../../assets/images/avatars/character.png"></v-img>
		</v-card>
	</div>
</template>

<script>
export default {
	setup() {
		const openDiscord = function () {
			window.open('https://discord.gg/2ePbGrc');
		};

		return {
			openDiscord,
		};
	},
};
</script>

<style lang="scss" scoped>
.join-discord {
	position: relative;
	.character-image {
		position: absolute;
		top: 0px;
		right: -12px;
		z-index: 1;
	}
}
</style>
