<template>
	<div>
		<GenericPopup v-if="modal" :modal="modal" @close="$emit('close')" :closeButton="false" persistent>
			<template #primaryHeader>Enable Your New TTS Global Stickers!</template>
			<template>
				<div>
					<p class="fs--14 fw--400 offWhite--text">
						Don't forget you can still enable your new Global TTS stickers from the Global TTS Tab in the
						Stickers section of the dashboard.
					</p>

					<v-btn color="pink" class="px-8 py-5 mb-2" depressed @click="$emit('close')">Continue</v-btn>
				</div>
			</template>
		</GenericPopup>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import GenericPopup from '@/components/GenericPopup';
import { mdiClose } from '@mdi/js';
export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GenericPopup,
	},
	setup() {
		return {
			closeIcon: mdiClose,
		};
	},
};
</script>

<style lang="scss" scoped>
.popup-header {
	position: relative;
	padding: 12px 0;
	background: url('../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}

.content-section {
	width: 90%;
	margin: auto;
}
</style>
