<template>
	<div v-if="isPromoEnabled" class="promotion-banners" id="promotionBanners">
		<div class="banner info-banner cursor-pointer" @click.self="openBanner()">
			<div class="action-link">
				<span v-if="promo.main.actionCopy" @click="openActionLinkBanner" class="text-underline fw--400">
					{{ promo.main.actionCopy }}
				</span>
			</div>
		</div>
		<div class="banner extension-banner cursor-pointer" @click="openBanner()"></div>
	</div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api';
import { useRouter } from '@core/utils';
import { saveEvent } from '@/utils/gtag';
import promo from '@/promos/promo.js';

export default {
	setup() {
		const { route } = useRouter();

		const isPromoEnabled = computed(() => {
			return promo && promo.main && promo.main.isEnabled;
		});

		const openBanner = function () {
			const ga = promo.main.ga;
			saveEvent({
				event: 'campaign_banner_click',
				params: {
					event_category: ga.event_category,
					event_label: ga.event_label,
					title: ga.title,
					unit: ga.unit,
					location: route.value.fullPath,
				},
			});

			if (promo.main.urlSameWindow) {
				window.location.href = promo.main.url;
			} else {
				window.open(promo.main.url);
			}
		};

		const openActionLinkBanner = function () {
			const ga = promo.main.actionGa;
			saveEvent({
				event: 'download_link_click',
				params: {
					event_category: ga.event_category,
					event_label: ga.event_label,
					title: ga.title,
					unit: ga.unit,
					location: route.value.fullPath,
				},
			});
			window.open(promo.main.actionUrl);
		};

		// Set background images for banners, if enabled.
		onMounted(() => {
			if (!isPromoEnabled.value) {
				return;
			}

			const left = document.getElementsByClassName('info-banner');
			if (left.length == 1) {
				left[0].style.backgroundImage = `url('${promo.main.image.left}')`;
			}

			const right = document.getElementsByClassName('extension-banner');
			if (right.length == 1) {
				right[0].style.backgroundImage = `url('${promo.main.image.right}')`;
			}
		});

		return {
			isPromoEnabled,
			promo,
			openBanner,
			openActionLinkBanner,
		};
	},
};
</script>

<style lang="scss" scoped>
.promotion-banners {
	position: relative;
	margin-bottom: 20px;
	height: 90px;

	.banner {
		height: 100%;
		position: absolute;
		top: 0;
		border-radius: 6px;
		overflow: hidden;
	}

	.info-banner {
		width: 728px;
		max-width: 728px;
		overflow: hidden;
		z-index: 1;
		background-size: 100%;
		background-repeat: no-repeat;
		border-radius: 6px;
		left: 0;

		.action-link {
			position: absolute;
			right: 5%;
			bottom: 7px;
			font-size: 13px;

			@media screen and (max-width: 699px) {
				right: 3.75%;
				top: 67%;
			}
			@media screen and (max-width: 650px) {
				right: 2%;
				top: 62%;
			}
			@media screen and (max-width: 599px) {
				right: 4%;
				top: 65%;
				font-size: 10px;
			}
			@media screen and (max-width: 520px) {
				right: 4%;
				top: 60%;
				font-size: 10px;
			}
			@media screen and (max-width: 499px) {
				right: 3%;
				top: 60%;
				font-size: 10px;
			}
			@media screen and (max-width: 480px) {
				right: 3%;
				top: 55%;
				font-size: 9px;
			}
			@media screen and (max-width: 448px) {
				right: 3%;
				top: 52%;
				font-size: 9px;
			}
			@media screen and (max-width: 410px) {
				right: 2.5%;
				top: 60%;
				font-size: 8px;
			}
		}
	}

	.extension-banner {
		width: 728px;
		max-width: 728px;
		background-size: 100%;
		background-repeat: no-repeat;
		border-radius: 6px;
		right: 0px;
	}

	@media screen and (max-width: 780px) {
		.info-banner,
		.extension-banner,
		.banner {
			width: 100%;
		}
	}

	@media screen and (max-width: 699px) {
		height: 75px;
		.action-link {
			right: 3%;
			bottom: 4px;
		}
	}

	@media screen and (max-width: 599px) {
		height: 65px;
	}

	@media screen and (max-width: 500px) {
		height: 60px;
	}

	@media screen and (max-width: 400px) {
		height: 45px;
	}
}
</style>
