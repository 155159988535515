<template>
	<div>
		<GenericPopup v-if="modal" :modal="modal" :maxWidth="950" @close="actions.skip" :closeButton="false" persistent>
			<template #primaryHeader>Feature Update! Enable your new Global A.I. TTS Stickers!</template>
			<template>
				<div>
					<p class="fs--14 fw--400 shinyPurple--text mt-0 mb-6">
						Our all-new <span class="pink--text fw--600">A.I. TTS Global Stickers</span> are here! Click the
						"<span class="pink--text fw--600">ENABLE ALL</span>" button below to turn them all on
						automatically, or browse the options below to turn on individual stickers. Your catalog of
						Global A.I. TTS stickers can now be found in the Stickers section of the dashboard. We've also
						added brand new settings that allow you to control your Global A.I. TTS preferences from the
						Settings page.
					</p>
					<VueSlickCarousel ref="carousel" v-bind="settings" class="mb-5">
						<div v-for="(item, index) in stickerArray" :key="index + '--tts'" class="px-7">
							<AssetsTTS :item="item" size="30" :isAnimatedMouthRequired="true" />
							<MusicPlayer
								:id="'voice-preview-' + item.id"
								class="mt-2"
								audio="https://actions.google.com/sounds/v1/emergency/ambulance_drive_siren.ogg" />
							<strong class="fs--14 fw--500 offWhite--text">Voice Preview</strong>
						</div>
					</VueSlickCarousel>

					<div class="d-flex flex-column align-center justify-center">
						<v-btn color="pink" class="px-8 py-5 mb-2" depressed @click="actions.enableAll"
							>Enable All</v-btn
						>
					</div>
				</div>
			</template>
		</GenericPopup>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import GenericPopup from '@/components/GenericPopup';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import AssetsTTS from '@/views/streamer/stickers/AssetsTTS.vue';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import { mdiClose } from '@mdi/js';
import { getUserId } from '@/utils/auth';
import { saveEvent } from '@/utils/gtag';
import { getVoicePreviewUrl } from '@/utils/tts-utils';

export default {
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GenericPopup,
		VueSlickCarousel,
		AssetsTTS,
		MusicPlayer,
	},
	setup(props, ctx) {
		const settings = ref({
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			touchThreshold: 5,
		});

		const actions = {
			getAudioUrl(item) {
				const cb = ref(new Date().getTime());
				return getVoicePreviewUrl(item.id) + '&cb=' + cb.value;
			},
			enableAll() {
				const id = getUserId();

				saveEvent({
					event: 'tts_enable_all_click',
					params: {
						event_category: 'engagement',
						event_label: 'User Enabled All Global TTS Stickers',
						user_id: id,
					},
				});

				ctx.emit('close');
			},

			skip() {
				saveEvent({
					event: 'tts_enable_all_click',
					params: {
						event_category: 'engagement',
						event_label: 'User Enabled All Global TTS Stickers',
					},
				});
				console.log('skipped');
				ctx.emit('skip');
			},
		};

		const slide = ref(0);
		const stickerArray = ref([
			{
				id: 'fc823034-81b6-484e-9132-baa245eefb70',
				uuid: 'fc823034-81b6-484e-9132-baa245eefb70',
				key: '5f11c9c9-3147-40fb-a6c0-a64b8ac002e1',
				sortOrder: 6,
				stickerType: 'TTS',
				enabled: true,
				isReward: false,
				isBanned: false,
				subOnly: false,
				sku: 'ticket_100',
				stickiness: 70,
				volume: 76,
				owner: '823788882',
				name: 'test 2',
				size: '0',
				isCustom: true,
				validated: false,
				staticView: '/na',
				iconView: '/na',
				sound: '/na',
				timestamp: null,
				isSlotUnlocked: null,
				slotUnlocksAt: 0,
				stickerBlob: null,
				audioBlob: null,
				rewardSlotThreshold: null,
				isStickerPlacementEnabled: false,
				tts: {
					displayMode: 'STICKER',
					stickerSlot: 0,
					baseImage: 'custom_stickers/823788882/tts/1684917287666.png',
					eyeImage: '0.0.1/tts/eyes/1.gif',
					eyeBlob: null,
					mouthImage: '0.0.1/tts/mouth/animated/1.gif',
					mouthBlob: null,
					closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
					closedMouthBlob: null,
					alertSound: null,
					alertSoundBlob: null,
					alertDurationInSeconds: 0,
					isEyeEnabled: true,
					isEyeFlipped: false,
					isMouthEnabled: true,
					isMouthFlipped: false,
					isClosedMouthEnabled: true,
					language: 'en',
					voiceId:
						'7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					isBonusTtsShareSlot: false,
					stickiness: 70,
					volume: 76,
					sku: 'ticket_100',
					subOnly: false,
					position: {
						eyeX: 126,
						eyeY: 41,
						mouthX: 124,
						mouthY: 260,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
					bounds: {
						eyeX: 76,
						eyeY: 16,
						mouthX: 89,
						mouthY: 235,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
				},
				ttsVoice: {
					id: '7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					gender: 'Male',
					languageCode: 'en-GB',
					languageName: 'British English',
					name: 'Brian',
					audioSource: 'AMAZON',
					ownership: 'GLOBAL',
					modelType: null,
					minBits: 50,
					isAccentEnhancement: false,
					stability: 30,
					style: 0,
					shares: [],
					sharedFrom: null,
				},
				searchTerms: null,
				isSlotConfigured: true,
			},
			{
				id: 'fc823034-81b6-484e-9132-baa245eefb71',
				uuid: 'fc823034-81b6-484e-9132-baa245eefb70',
				key: '5f11c9c9-3147-40fb-a6c0-a64b8ac002e1',
				sortOrder: 6,
				stickerType: 'TTS',
				enabled: true,
				isReward: false,
				isBanned: false,
				subOnly: false,
				sku: 'ticket_100',
				stickiness: 70,
				volume: 76,
				owner: '823788882',
				name: 'test 2',
				size: '0',
				isCustom: true,
				validated: false,
				staticView: '/na',
				iconView: '/na',
				sound: '/na',
				timestamp: null,
				isSlotUnlocked: null,
				slotUnlocksAt: 0,
				stickerBlob: null,
				audioBlob: null,
				rewardSlotThreshold: null,
				isStickerPlacementEnabled: false,
				tts: {
					displayMode: 'STICKER',
					stickerSlot: 0,
					baseImage: 'custom_stickers/823788882/tts/1684917287666.png',
					eyeImage: '0.0.1/tts/eyes/1.gif',
					eyeBlob: null,
					mouthImage: '0.0.1/tts/mouth/animated/1.gif',
					mouthBlob: null,
					closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
					closedMouthBlob: null,
					alertSound: null,
					alertSoundBlob: null,
					alertDurationInSeconds: 0,
					isEyeEnabled: true,
					isEyeFlipped: false,
					isMouthEnabled: true,
					isMouthFlipped: false,
					isClosedMouthEnabled: true,
					language: 'en',
					voiceId:
						'7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					isBonusTtsShareSlot: false,
					stickiness: 70,
					volume: 76,
					sku: 'ticket_100',
					subOnly: false,
					position: {
						eyeX: 126,
						eyeY: 41,
						mouthX: 124,
						mouthY: 260,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
					bounds: {
						eyeX: 76,
						eyeY: 16,
						mouthX: 89,
						mouthY: 235,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
				},
				ttsVoice: {
					id: '7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					gender: 'Male',
					languageCode: 'en-GB',
					languageName: 'British English',
					name: 'Brian',
					audioSource: 'AMAZON',
					ownership: 'GLOBAL',
					modelType: null,
					minBits: 50,
					isAccentEnhancement: false,
					stability: 30,
					style: 0,
					shares: [],
					sharedFrom: null,
				},
				searchTerms: null,
				isSlotConfigured: true,
			},
			{
				id: 'fc823034-81b6-484e-9132-baa245eefb72',
				uuid: 'fc823034-81b6-484e-9132-baa245eefb70',
				key: '5f11c9c9-3147-40fb-a6c0-a64b8ac002e1',
				sortOrder: 6,
				stickerType: 'TTS',
				enabled: true,
				isReward: false,
				isBanned: false,
				subOnly: false,
				sku: 'ticket_100',
				stickiness: 70,
				volume: 76,
				owner: '823788882',
				name: 'test 2',
				size: '0',
				isCustom: true,
				validated: false,
				staticView: '/na',
				iconView: '/na',
				sound: '/na',
				timestamp: null,
				isSlotUnlocked: null,
				slotUnlocksAt: 0,
				stickerBlob: null,
				audioBlob: null,
				rewardSlotThreshold: null,
				isStickerPlacementEnabled: false,
				tts: {
					displayMode: 'STICKER',
					stickerSlot: 0,
					baseImage: 'custom_stickers/823788882/tts/1684917287666.png',
					eyeImage: '0.0.1/tts/eyes/1.gif',
					eyeBlob: null,
					mouthImage: '0.0.1/tts/mouth/animated/1.gif',
					mouthBlob: null,
					closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
					closedMouthBlob: null,
					alertSound: null,
					alertSoundBlob: null,
					alertDurationInSeconds: 0,
					isEyeEnabled: true,
					isEyeFlipped: false,
					isMouthEnabled: true,
					isMouthFlipped: false,
					isClosedMouthEnabled: true,
					language: 'en',
					voiceId:
						'7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					isBonusTtsShareSlot: false,
					stickiness: 70,
					volume: 76,
					sku: 'ticket_100',
					subOnly: false,
					position: {
						eyeX: 126,
						eyeY: 41,
						mouthX: 124,
						mouthY: 260,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
					bounds: {
						eyeX: 76,
						eyeY: 16,
						mouthX: 89,
						mouthY: 235,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
				},
				ttsVoice: {
					id: '7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					gender: 'Male',
					languageCode: 'en-GB',
					languageName: 'British English',
					name: 'Brian',
					audioSource: 'AMAZON',
					ownership: 'GLOBAL',
					modelType: null,
					minBits: 50,
					isAccentEnhancement: false,
					stability: 30,
					style: 0,
					shares: [],
					sharedFrom: null,
				},
				searchTerms: null,
				isSlotConfigured: true,
			},
			{
				id: 'fc823034-81b6-484e-9132-baa245eefb73',
				uuid: 'fc823034-81b6-484e-9132-baa245eefb70',
				key: '5f11c9c9-3147-40fb-a6c0-a64b8ac002e1',
				sortOrder: 6,
				stickerType: 'TTS',
				enabled: true,
				isReward: false,
				isBanned: false,
				subOnly: false,
				sku: 'ticket_100',
				stickiness: 70,
				volume: 76,
				owner: '823788882',
				name: 'test 2',
				size: '0',
				isCustom: true,
				validated: false,
				staticView: '/na',
				iconView: '/na',
				sound: '/na',
				timestamp: null,
				isSlotUnlocked: null,
				slotUnlocksAt: 0,
				stickerBlob: null,
				audioBlob: null,
				rewardSlotThreshold: null,
				isStickerPlacementEnabled: false,
				tts: {
					displayMode: 'STICKER',
					stickerSlot: 0,
					baseImage: 'custom_stickers/823788882/tts/1684917287666.png',
					eyeImage: '0.0.1/tts/eyes/1.gif',
					eyeBlob: null,
					mouthImage: '0.0.1/tts/mouth/animated/1.gif',
					mouthBlob: null,
					closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
					closedMouthBlob: null,
					alertSound: null,
					alertSoundBlob: null,
					alertDurationInSeconds: 0,
					isEyeEnabled: true,
					isEyeFlipped: false,
					isMouthEnabled: true,
					isMouthFlipped: false,
					isClosedMouthEnabled: true,
					language: 'en',
					voiceId:
						'7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					isBonusTtsShareSlot: false,
					stickiness: 70,
					volume: 76,
					sku: 'ticket_100',
					subOnly: false,
					position: {
						eyeX: 126,
						eyeY: 41,
						mouthX: 124,
						mouthY: 260,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
					bounds: {
						eyeX: 76,
						eyeY: 16,
						mouthX: 89,
						mouthY: 235,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
				},
				ttsVoice: {
					id: '7b0a202022696422203a2022427269616e222c0a202022617564696f536f7572636522203a2022414d415a4f4e220a7d',
					gender: 'Male',
					languageCode: 'en-GB',
					languageName: 'British English',
					name: 'Brian',
					audioSource: 'AMAZON',
					ownership: 'GLOBAL',
					modelType: null,
					minBits: 50,
					isAccentEnhancement: false,
					stability: 30,
					style: 0,
					shares: [],
					sharedFrom: null,
				},
				searchTerms: null,
				isSlotConfigured: true,
			},
		]);
		return {
			slide,
			settings,
			stickerArray,
			closeIcon: mdiClose,
			actions,
		};
	},
};
</script>
